import React from "react";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Card(props) {

    return (
        <div className={classNames(props.className, "overflow-hidden rounded-lg bg-white shadow")}>
            <div className="px-4 py-5 sm:p-6">{props.content}</div>
        </div>
    )
}