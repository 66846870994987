import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from '../components/Footer';
import Card from '../components/Card';
import api from "../services/api";
import Table from "../components/Table";

export default function ReportsPage() {

    const [loading, setLoading] = useState(false)
    const [reports, setReports] = useState([])
    const [error, setError] = useState(null)

    useEffect(() => {
        api.personAnswers.getReports().then((response) => {
            setReports(response)
            setLoading(false)
        }).catch((error) => setError(error))
    }, [])

    const card1 = (
        <>
            <p className="text-white text-7xl font-extrabold">
                {reports?.participants}
            </p>
            <p className="text-white mt-12 opacity-70 font-medium text-2xl">Participantes</p>
        </>
    )

    const card2 = (
        <>
            <p className="text-white font-medium text-lg">Pregunta con más respuestas correctas</p>
            <p className="text-white mt-2 text-xl font-bold">
                {reports?.questionWithMoreCorrectAnswers?.question}
            </p>
            <p className="text-white mt-8 font-medium opacity-70 text-2xl">{reports?.questionWithMoreCorrectAnswers?.total} Respuestas</p>
        </>
    )

    const card3 = (
        <>
            <p className="text-white font-medium text-lg">Pregunta con más respuestas incorrectas</p>
            <p className="text-white mt-2 text-xl font-bold">
                {reports?.questionWithMoreWrongAnswers?.question}
            </p>
            <p className="text-white mt-8 font-medium opacity-70 text-2xl">{reports?.questionWithMoreWrongAnswers?.total} Respuestas</p>
        </>
    )

    return (
        <>
            <div className="text-white min-h-screen h-full flex flex-col bg-white">
                <div className="px-12 mt-10 flex flex-col">
                    <h1 className="text-primary text-5xl font-extrabold">
                        Vacu Trivia
                    </h1>
                    <p className="text-text1 mt-2 text-2xl font-medium">Reportes</p>
                    <div className="mt-4 flex flex-1 flex-col gap-y-4 sm:gap-y-0 sm:flex-row sm:gap-x-6">
                        <Card className="bg-secondary sm:w-1/3 rounded-[20px]" content={card1} />
                        <Card className="bg-report1 sm:w-1/3 rounded-[20px]" content={card2} />
                        <Card className="bg-report2 sm:w-1/3 rounded-[20px]" content={card3} />
                    </div>
                    <Table questions={reports.questions} />
                </div >
                <Footer containerClassName="mt-4" />
            </div>
        </>
    );
}
