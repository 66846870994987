import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Answer from "../components/Answer";
import api from "../services/api";
import ProgressBar from '../components/ProgressBar'
import VirusIcon from '../assets/Virus.png'

export default function TestPage() {

    const [loading, setLoading] = useState(true)
    const navigate = useNavigate();
    const [questions, setQuestions] = useState([])
    const [index, setIndex] = useState(0)
    const [error, setError] = useState(null)
    const [selection, setSelection] = useState(0)
    const [progress, setProgress] = useState(0)
    const [score, setScore] = useState(0)
    const [answers, setAnswers] = useState([])
    const [tries, setTries] = useState([10]);
    const [rounds, setRounds] = useState(0);

    useEffect(() => {
        api.questions.startTest().then((response) => {
            setQuestions(response.questions)
            setLoading(false)
        }).catch((error) => setError(error))
    }, [])

    const goNext = (selection, isCorrect) => {
        setSelection(selection)
        setProgress(progress + 1)
        setTimeout(function () {
            if (index < questions.length - 1 || !isCorrect) {
                if (index < 10 && isCorrect) {
                    setScore(score + 1)
                } else if (isCorrect) {
                } else {
                    let aux = [...tries]
                    let obj = [...questions, { ...questions[index], attempt: questions[index]?.attempt ? questions[index].attempt + 1 : 2 }]
                    aux[questions[index]?.attempt ? questions[index].attempt : 1] = aux[questions[index]?.attempt ? questions[index].attempt : 1] ? aux[questions[index]?.attempt ? questions[index].attempt : 1] + 1 : 1
                    obj[obj.length - 1].answers[selection - 1].disabled = true
                    setQuestions(obj)
                    setTries(aux)
                }
                setAnswers([...answers, { answer: questions[index].answers[selection - 1].id, attempt: questions[index]?.attempt ? questions[index].attempt : 1 }])
                if (progress === tries[rounds] - 1) {
                    setProgress(0)
                    setRounds(rounds + 1)
                }
                setIndex(index + 1)
                setSelection(0)
            } else {
                if (index < 10) {
                    setScore(score + 1)
                }
                setAnswers([...answers, { answer: questions[index].answers[selection - 1].id, attempt: questions[index]?.attempt ? questions[index].attempt : 1 }])
            }
        }, 1000)
    }

    useEffect(() => {
        if (answers.length === questions.length && answers.length > 1 && questions.length > 1) {
            navigate('/results', { state: { answers: answers, score: score } })
        }
    }, [answers])

    return (
        <>
            {loading ?
                <div className="h-full min-h-screen items-center text-white flex flex-col bg-primary">
                    <h1 className="mt-8 sm:mt-16 font-extrabold text-5xl">Vacu Trivia</h1>
                    <div className="flex h-screen items-center justify-center ">
                        <div className="w-16 h-16 border-b-2 border-white rounded-full animate-spin"></div>
                    </div>
                </div >
                :
                <div className="h-full min-h-screen items-center text-white flex flex-col bg-primary">
                    <img className="absolute top-24 left-40 h-auto self-center invisible sm:visible w-20" src={VirusIcon} alt="VirusIcon" />
                    <img className="absolute top-52 left-20 lg:right-20 h-auto self-center invisible sm:visible w-16" src={VirusIcon} alt="VirusIcon" />
                    <img className="absolute bottom-40 right-32 h-auto self-center invisible sm:visible w-16" src={VirusIcon} alt="VirusIcon" />
                    <ProgressBar score={Math.round(progress / tries[rounds] * 100)} />
                    <h1 className="mt-8 sm:mt-16 font-extrabold text-5xl">Vacu Trivia</h1>
                    <p className="text-lg mt-10 font-medium">Elige la respuesta que creas correcta</p>
                    <div className="border border-secondary w-1/4 mt-10"></div>
                    <p className="text-2xl mt-10 text-center font-medium px-2 sm:px-0">{questions[index].text}</p>
                    <div className="mt-8 w-full flex sm:w-1/2 flex-col space-y-4 px-4 sm:px-0 pb-8 sm:pb-0">
                        <Answer idx={1} selection={selection} goNext={goNext} disabled={questions[index].answers[0].disabled} answer={questions[index].answers[0].answer} isCorrect={questions[index].answers[0].isCorrectAnswer} />
                        <Answer idx={2} selection={selection} goNext={goNext} disabled={questions[index].answers[1].disabled} answer={questions[index].answers[1].answer} isCorrect={questions[index].answers[1].isCorrectAnswer} />
                        <Answer idx={3} selection={selection} goNext={goNext} disabled={questions[index].answers[2].disabled} answer={questions[index].answers[2].answer} isCorrect={questions[index].answers[2].isCorrectAnswer} />
                    </div>
                </div >
            }
        </>
    );
}
