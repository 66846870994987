import React, { useEffect, useState } from "react";
import './ProgressBar.css'

export default function ProgressBar(props) {

    const [value, setValue] = useState(0)

    useEffect(() => {
        setValue(props.score)
    }, [props.score])

    return (
        <div className="meter animate w-full">
            <span style={{ width: `${value}%` }}></span>
        </div>
    )
}