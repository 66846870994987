import axios from "axios";

const errorCodes = {
  badRequest: "badRequest",
  unauthorized: "unauthorized",
  forbidden: "forbidden",
  notFound: "notFound",
  serverError: "serverError",
  unexpected: "unexpected",
  invalidCredentials: "invalidCredentials",
};

class API {
  constructor(collection) {
    this.collectionUrl = `${process.env.REACT_APP_API_BASE_URL}/${collection}`;
  }

  _handleError(error) {
    if (error.response?.status === 400)
      return Promise.reject({
        code: errorCodes.badRequest,
        data: error.response?.data,
      });
    if (error.response?.status === 401)
      return Promise.reject({
        code: errorCodes.unauthorized,
        data: error.response?.data,
      });
    if (error.response?.status === 403)
      return Promise.reject({
        code: errorCodes.forbidden,
        data: error.response?.data,
      });
    if (error.response?.status === 404)
      return Promise.reject({
        code: errorCodes.notFound,
        data: error.response?.data,
      });
    if (500 <= error.response?.status <= 599)
      return Promise.reject({
        code: errorCodes.serverError,
        data: error.response?.data,
      });
    return Promise.reject({
      code: errorCodes.unexpected,
      data: error.response?.data,
    });
  }

  async getMulti(
    ordering = null,
    search = null,
    limit = 100,
    offset = 0,
    extra = {}
  ) {
    const params = { limit, offset, ...extra };
    if (ordering) params.ordering = ordering;
    if (search && search.length > 0) params.search = search;
    try {
      const response = await axios.get(`${this.collectionUrl}/`, { params });
      return Promise.resolve(response.data);
    } catch (error) {
      return this._handleError(error);
    }
  }

  async getOne(id) {
    try {
      const response = await axios.get(`${this.collectionUrl}/${id}/`);
      return Promise.resolve(response.data);
    } catch (e) {
      return this._handleError(e);
    }
  }

  async create(data) {
    try {
      const response = await axios.post(`${this.collectionUrl}/`, data);
      return Promise.resolve(response.data);
    } catch (e) {
      return this._handleError(e);
    }
  }

  async update(id, data, partial = false) {
    try {
      let response;
      if (partial)
        response = await axios.patch(`${this.collectionUrl}/${id}/`, data);
      else response = await axios.put(`${this.collectionUrl}/${id}/`, data);
      return Promise.resolve(response.data);
    } catch (e) {
      return this._handleError(e);
    }
  }

  async delete(id) {
    try {
      return await axios.delete(`${this.collectionUrl}/${id}/`);
    } catch (e) {
      return this._handleError(e);
    }
  }
}

class QuestionsAPI extends API {
  async startTest() {
    try {
      const response = await axios.post(`${this.collectionUrl}/start/`, { trivia: process.env.REACT_APP_TRIVIA_NUMBER });
      return Promise.resolve(response.data);
    } catch (e) {
      return this._handleError(e);
    }
  }
}

class ReportsAPI extends API {
  async getReports() {
    try {
      const response = await axios.post(`${this.collectionUrl}/report/`, { trivia: process.env.REACT_APP_TRIVIA_NUMBER });
      return Promise.resolve(response.data);
    } catch (e) {
      return this._handleError(e);
    }
  }
}

const api = {
  trivia: new API("trivia"),
  questions: new QuestionsAPI("questions"),
  answers: new API("answers"),
  personAnswers: new ReportsAPI("person-answers"),
  rounds: new API("rounds"),
};

export default api;
export { errorCodes, API };
