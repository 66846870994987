import { Routes, Route, BrowserRouter as Router, Navigate } from 'react-router-dom';
import HomePage from './pages/HomePage'
import TestPage from './pages/TestPage'
import ResultsPage from './pages/ResultsPage'
import ReportsPage from './pages/ReportsPage'

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/test" element={<TestPage />} />
        <Route path="/results" element={<ResultsPage />} />
        <Route path="/reports" element={<ReportsPage />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router >
  );
}

export default App;
