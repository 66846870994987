import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import WalkIcon from "../assets/WalkPerson.png";
import Footer from "../components/Footer";

export default function HomePage() {

    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();

    return (
        <>
            <div className="invisible sm:visible absolute top-16 left-48 w-8 h-8 border-2 rounded-full bg-[#1334E0] border-[#1334E0] shrink-0"></div>
            <div className="invisible sm:visible absolute top-28 left-20 w-28 h-28 border rounded-full border-[#1334E0] shrink-0"></div>
            <div className="invisible sm:visible absolute bottom-60 right-2 lg:right-16 w-56 h-56 border-2 rounded-full bg-[#1334E0] border-[#1334E0] shrink-0"></div>
            <div className="invisible sm:visible absolute bottom-[480px] right-2 lg:right-72 w-28 h-28 border rounded-full border-[#1334E0] shrink-0"></div>
            <img className="absolute bottom-60 right-2 z-10 lg:right-20 h-auto self-center invisible sm:visible w-64" src={WalkIcon} alt="WalkIcon" />
            <div className="items-center text-white min-h-screen h-full flex flex-col bg-primary">
                <div className="items-center text-white h-2/3 flex flex-col bg-primary">
                    <div className="text-center">
                        <h1 className="mt-[40%] sm:mt-[25%] z-20 font-extrabold text-5xl sm:text-7xl">Vacu Trivia</h1>
                        <p className="text-lg sm:text-2xl mt-10 font-medium">Si estás protegido... ¡ganamos todos!</p>
                        <button onClick={() => navigate('/test')} className="bg-secondary mt-10 items-center py-4 px-16 w-fit rounded-full font-bold text-lg">Empezar a jugar</button>
                    </div>
                </div >
                <Footer containerClassName="bottom-0 absolute sm:h-auto" />
            </div>
        </>
    );
}
