
export default function Table(props) {
    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <div className="mt-8 flex flex-col">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        <table className="min-w-full divide-y divide-gray-300">
                            <thead>
                                <tr>
                                    <th
                                        scope="col"
                                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-text1 sm:pl-6 md:pl-0"
                                    >
                                        Pregunta
                                    </th>
                                    <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-text1">
                                        Respuestas correctas en el primer intento
                                    </th>
                                    <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-text1">
                                        Respuestas correctas en el segundo intento
                                    </th>
                                    <th scope="col" className="py-3.5 text-left text-sm font-semibold text-text1 pl-3 pr-4 sm:pr-6 md:pr-0">
                                        Respuestas correctas en más de 2 intentos
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                                {props.questions && props.questions.map((question) => (
                                    <tr key={question.id}>
                                        <td className="py-4 pl-4 pr-3 text-sm font-medium text-secondary sm:pl-6 md:pl-0">
                                            {question.question}
                                        </td>
                                        <td className="whitespace-nowrap py-4 px-3 text-sm text-secondary">{question.firstAttempt}</td>
                                        <td className="whitespace-nowrap py-4 px-3 text-sm text-secondary">{question.secondAttempt}</td>
                                        <td className="whitespace-nowrap py-4 px-3 text-sm text-secondary  sm:pr-6 md:pr-0">{question.thirdOrMoreAttempts}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
