import React from "react";
import CIRDLogo from '../assets/CIRD.png'
import GobiernoNacionalLogo from '../assets/GobiernoNacional.png'
import MDSLogo from '../assets/ministeriodesalud1.png'
import PyGenteLogo from '../assets/PyGente.png'
import USAIDLogo from '../assets/USAID.png'
import USAIDVLogo from '../assets/USAIDV.png'
import PaiLogo from '../assets/LogoPai.svg'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Footer(props) {

    return (
        <div className={classNames(props.containerClassName, "pb-8 w-full items-center text-center text-text1 flex flex-col bg-white")}>
            <div className="mt-4 sm:mt-4 flex flex-wrap justify-center sm:grid-cols-none sm:flex align-middle flex-shrink-0">
                <div className="flex gap-x-4 sm:gap-x-12">
                    <img
                        className="h-auto self-center w-20 sm:w-40"
                        src={MDSLogo}
                        alt="Ministerio"
                    />
                    <img
                        className="h-auto self-center w-24 sm:w-44"
                        src={PaiLogo}
                        alt="PaiLogo"
                    />
                    <img
                        className="h-auto self-center w-16 sm:w-36"
                        src={GobiernoNacionalLogo}
                        alt="Gobierno Nacional"
                    />
                    <img
                        className="h-auto self-center w-16 sm:w-28"
                        src={PyGenteLogo}
                        alt="Paraguay de la gente"
                    />
                </div>
                <div className="mt-2 sm:mt-0 flex gap-x-6 sm:ml-12 sm:gap-x-12">
                    <img
                        className="h-auto self-center w-28 sm:w-40"
                        src={USAIDLogo}
                        alt="USAID"
                    />
                    <img
                        className="h-auto self-center w-14 sm:w-20"
                        src={CIRDLogo}
                        alt="CIRD"
                    />
                </div>
            </div>
            <p className="font-medium mt-4 sm:mt-0 text-xs text-center sm:text-left sm:text-sm">Recordá: La protección efectiva se logra completando los esquemas de vacunación.</p>
            <p className="font-medium text-xs text-center sm:text-left sm:text-sm">Y la efectividad es mayor en cuanto más personas se vacunan.</p>
            <p className="font-medium text-xs sm:text-sm mt-2">Proyecto “Fortaleciendo la Respuesta contra el COVID-19”</p>
        </div >
    );
}