import React from "react";
import CheckIcon from '../assets/checkmark-sharp.png'
import CloseIcon from '../assets/close-sharp.png'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Answer(props) {

    return (
        <button onClick={() => props.disabled ? null : props.goNext(props.idx, props.isCorrect)} className={classNames(props.idx === props.selection ? (props.isCorrect ? "focus:focus:bg-green-600 focus:text-white" : "focus:focus:bg-red-600 focus:text-white") : '', "min-w-fit border-4 border-transparent sm:w-full text-sm text-left sm:text-lg font-normal text-text1 rounded-full p-4 items-center h-fit flex bg-gray1")}>
            <div className="ml-2 h-5 w-5 flex shrink-0">
                {props.isCorrect ? <img src={CheckIcon} className={classNames(props.idx === props.selection ? "visible" : "hidden", "h-5 w-5 mx-auto mt-px")} >
                </img> : <img src={CloseIcon} className={classNames(props.idx === props.selection ? "visible" : "hidden", "h-5 w-5 mx-auto mt-px")} >
                </img>}
            </div>
            <p className={classNames(props.disabled ? 'opacity-50' : '', "ml-4")}>{props.answer}</p>
        </button>
    );
}