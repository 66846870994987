import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import { useLocation, useNavigate } from 'react-router-dom';
import TextInput from "../components/TextInput";
import api from "../services/api";
import DeskIcon from "../assets/DeskPerson.png"

export default function ResultsPage() {

    const [error, setError] = useState('')
    const [person, setPerson] = useState({})
    const [idRound, setIdRound] = useState(null)
    const [completed, setCompleted] = useState(false)
    const location = useLocation()
    const navigate = useNavigate();

    useEffect(() => {
        api.rounds.create({ totalPoints: location?.state?.score, answers: location?.state?.answers }).then((response) => {
            console.log(response)
            setIdRound(response.id)
        }).catch(response => {
            for (var element in response.message) {
                error = true
                if (Object.prototype.hasOwnProperty.call(response.message, element)) {
                    if (response.message[element] != 'Este campo no puede estar en blanco.')
                        setError(response.message[element])
                }
            }
        }
        ).then(() => error ? null : null)
    }, [])

    const handleChange = (field, value) => setPerson((prev) => ({ ...prev, [field]: value }));

    const handleSubmit = () => {
        let error = false
        if (!person.name || !person.cellphone || !person.email || !person.govId) {
            setError('Debe completar todos los campos')
            error = true
        } else {
            setError('')
        }
        if (!error) {
            api.rounds.update(idRound, { ...person, totalPoints: location.state.score, answers: location.state.answers }).catch(response => {
                for (var element in response.message) {
                    error = true
                    if (Object.prototype.hasOwnProperty.call(response.message, element)) {
                        if (response.message[element] != 'Este campo no puede estar en blanco.')
                            setError(response.message[element])
                    }
                }
            }
            ).then(() => error ? null : setCompleted(true))
        }
    }

    return (
        <>
            {/* <div className="overflow-clip absolute bottom-24 invisible sm:visible z-10 -right-32 md:w-[300px] md:h-[300px] lg:w-[400px] lg:h-[400px] border-2 rounded-full bg-[#1334E0] border-[#1334E0] shrink-0"></div> */}
            <img className="absolute bottom-52 z-20 right-10 h-auto self-center invisible md:visible md:w-40 lg:w-64" src={DeskIcon} alt="DeskIcon" />
            <div className="items-center text-white h-full sm:max-h-screen space-y-8 flex flex-col bg-primary">
                <div className="grid grid-cols-1 min-h-screen px-6 sm:px-32 sm:space-x-16">
                    {/* <div className="flex flex-col">
                        <h1 className="mt-[5%] font-extrabold text-5xl">Vacu Trivia</h1>
                        {!completed ?
                            <>
                                <p className="text-lg sm:text-xl mt-4 font-medium">Completa tus datos y participá en un sorteo</p>
                                <div className="flex flex-col mt-6 space-y-4">
                                    <TextInput handleChange={handleChange} name="name" containerClassName="w-full" placeholder="Nombre y Apellido" />
                                    <TextInput handleChange={handleChange} name="govId" containerClassName="w-full" placeholder="C.I." />
                                    <TextInput handleChange={handleChange} name="email" containerClassName="w-full" placeholder="Correo electrónico" />
                                    <TextInput handleChange={handleChange} name="cellphone" containerClassName="w-full" placeholder="Número de teléfono" />
                                    <button onClick={() => handleSubmit()} className="bg-secondary mt-10 h-12 items-center px-16 w-full rounded-full font-bold text-lg">Participar</button>
                                    <a target='_blank' href='/reports' className="text-md text-center underline font-medium">Ver resultados generales</a>
                                    <p className="mt-2 text-sm text-center font-bold text-red-500">{error}</p>
                                </div>
                            </> :
                            <div>
                                <p className="text-lg sm:text-2xl mt-10 font-medium">Gracias por participar del sorteo!</p>
                                <button onClick={() => navigate('/')} className="bg-secondary mt-10 h-12 items-center px-16 w-full rounded-full font-bold text-lg">Volver a jugar</button>
                                <div className="flex justify-center">
                                    <a target='_blank' href='/reports' className="text-md text-center mt-2 underline font-medium">Ver resultados generales</a>
                                </div>
                            </div>
                        }
                    </div> */}
                    <div className="flex flex-col text-center">
                        <h1 className="mt-[10%] font-extrabold text-5xl">Vacu Trivia</h1>
                        <h1 className="mt-[5%] sm:mt-[10%] font-extrabold text-xl">Tu puntaje:</h1>
                        <div className="border-2 mt-4 rounded-full mx-auto bg-secondary border-secondary items-center flex flex-col h-36 w-36 shrink-0">
                            <p className="text-4xl sm:text-5xl mt-8 font-extrabold">{location?.state?.score ? location?.state?.score : '-'}</p>
                            <p className="text-lg font-bold opacity-50">/10</p>
                        </div>
                        <p className="text-lg sm:text-2xl mt-4 font-medium">{location?.state?.score < 5 ? '¡La próxima lo harás mejor!' : location?.state?.score < 9 ? '¡Seguí intentando!' : '¡Felicidades!'}</p>
                        <button onClick={() => navigate('/')} className="bg-secondary mt-10 h-12 items-center px-16 w-full rounded-full font-bold text-lg">Volver a jugar</button>
                        <div className="flex justify-center">
                            <a target='_blank' href='/reports' className="text-md text-center mt-2 underline font-medium">Ver resultados generales</a>
                        </div>
                    </div>
                </div>
                <Footer containerClassName="z-20 bottom-0 h-44 sm:absolute" />
            </div >
        </>
    );
}
